import React from "react";

import { IterativeMethodDisplay } from "./IterativeMethodDisplay";
import { defaultPlotProps } from "./LinearSystemPlot";

const jacobiStep = (A: number[][], b: number[], x: number[]): number[] => {
    const xNew = [...x];
    for (let i = 0; i < 2; i++) {
        let sum = 0;
        for (let j = 0; j < 2; j++) {
            if (i !== j) {
                sum += A[i][j] * x[j];
            }
        }
        xNew[i] = (b[i] - sum) / A[i][i];
    }
    return xNew;
};

const plotProps = defaultPlotProps();

const A = [
    [2, 1],
    [5, 7],
];
const b = [11, 13];
const x0 = [-4, 7];

// Graph component to visualize the equations and iterations
export function JacobiMethodDisplay() {
    return <IterativeMethodDisplay method={jacobiStep} spec={{ A, b, x0 }} plotProps={plotProps} />;
}
