import React from "react";

import { Link } from "react-router-dom";

import type { MenuItem } from "./defs";

export function ContentsMenu({ sections }: { sections: MenuItem[] }) {
    return (
        <div className="menu">
            <table style={{ paddingLeft: 5 }}>
                <tbody>
                    {sections.map((s) => (
                        <tr key={s.section.sectionNumber}>
                            <td>
                                <Link to={s.section.path}>
                                    <div style={{ display: "flex", gap: 20, paddingTop: 5 }}>
                                        <div>{s.section.sectionNumber}</div>
                                        <div>{s.section.shortTitle}</div>
                                    </div>
                                </Link>
                                <div style={{ paddingLeft: 10 }}>
                                    <ContentsMenu sections={s.menuitems ? s.menuitems : []} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
