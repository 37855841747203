import React, { useState, useEffect } from "react";

import NavigationControls from "./NavigationControls";
import type { PlotPropsExt } from "./FunctionPlot";
import { initPlot, plotIntervalMarkers } from "./FunctionPlot";
import { IterationIntervalsTable } from "./IterationIntervalsTable";

export type Interval = [number, number];

export interface FunctionAndInterval {
    func: (x: number) => number;
    interval: Interval;
}

export interface NLMResult {
    x: number;
    y: number;
    interval: Interval;
}

export type NonlinearMethod = (func: (x: number) => number, interval: Interval, points?: number[][]) => NLMResult;

// function bootstrap(func: (x: number) => number, interval: Interval): number[][] {
//     return [
//         [interval[0], func(interval[0])],
//         [interval[1], func(interval[1])],
//     ];
// }

// Graph component to visualize the equations and iterations
export function FunctionDisplay({
    method,
    spec,
    plotProps,
}: {
    method: NonlinearMethod;
    spec: FunctionAndInterval;
    plotProps: PlotPropsExt;
}) {
    // Input state variables
    const [interval, setInterval] = useState(spec.interval); // Right-hand side b

    // Iteration state
    //const [iterations, setIterations] = useState<number[][]>(bootstrap(spec.func, interval));
    const [intervals, setIntervals] = useState<Interval[]>([interval]);

    // State for the expandable cases section
    // const [isExpanded, setIsExpanded] = useState(false);

    // D3 SVG ref
    const svgRef = React.useRef<SVGSVGElement>(null);

    useEffect(() => {
        //const its = bootstrap(spec.func, interval);
        //setIterations(its);
        setIntervals([interval]);
        initPlot(svgRef.current, spec.func, interval, plotProps);
    }, [interval]);

    // Update the graph when the iterations change
    useEffect(() => {
        if (!svgRef.current) return;
        plotIntervalMarkers(intervals, spec.func, plotProps);
    }, [intervals]);

    // Event handlers for buttons
    const stepForward = () => {
        const newX = method(spec.func, intervals[intervals.length - 1]);
        //setIterations([...iterations, [newX.x, newX.y]]);
        setIntervals([...intervals, newX.interval]);
    };

    const stepBackward = () => {
        if (intervals.length > 1) {
            //setIterations(iterations.slice(0, iterations.length - 1));
            setIntervals(intervals.slice(0, intervals.length - 1));
        }
    };

    const resetIterations = () => {
        //setIterations(bootstrap(spec.func, interval));
        setIntervals([interval]);
    };

    return (
        <div>
            <div className="input-container">
                <div>
                    <label>Interval:</label>
                    <div className="matrix-input">
                        <input
                            type="number"
                            value={interval[0]}
                            onChange={(e) => setInterval([+e.target.value, interval[1]])}
                        />
                        <input
                            type="number"
                            value={interval[1]}
                            onChange={(e) => setInterval([interval[0], +e.target.value])}
                        />
                    </div>
                </div>
            </div>

            <NavigationControls
                stepForward={stepForward}
                stepBackward={stepBackward}
                resetIterations={resetIterations}
                isStepBackwardDisabled={intervals.length === 1}
            />

            <svg ref={svgRef} width={plotProps.width} height={plotProps.height} />
            <IterationIntervalsTable intervals={intervals} />
        </div>
    );
}
