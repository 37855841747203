import React from "react";
import { Tooltip } from "react-tooltip";

interface NavigationControlsProps {
    resetIterations: () => void;
    stepBackward: () => void;
    stepForward: () => void;
    isStepBackwardDisabled: boolean; // To disable the backward button when needed
}

const NavigationControls: React.FC<NavigationControlsProps> = ({
    resetIterations,
    stepBackward,
    stepForward,
    isStepBackwardDisabled,
}) => {
    return (
        <div>
            <button onClick={resetIterations} data-tooltip-id="tooltip-reset" data-tooltip-content="Reset">
                &#x21E4;
            </button>
            <button
                onClick={stepBackward}
                disabled={isStepBackwardDisabled}
                data-tooltip-id="tooltip-step-back"
                data-tooltip-content="Step Back"
            >
                &larr;
            </button>
            <button onClick={stepForward} data-tooltip-id="tooltip-step-forward" data-tooltip-content="Step Forward">
                &rarr;
            </button>
            <Tooltip id="tooltip-step-back" />
            <Tooltip id="tooltip-step-forward" />
            <Tooltip id="tooltip-reset" />
        </div>
    );
};

export default NavigationControls;
