import React from "react";

import { BisectionMethodDisplay } from "~/applets/BisectionMethodDisplay";
import { ChapterWrapper } from "~/ChapterWrapper";
import { Path } from "~/paths";

export const BisectionMethodMeta = {
    title: "Bisection Method",
    shortTitle: "Bisection Method",
    path: Path.bisection_method,
    element: (sectionNumber: string) => <BisectionMethod sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

export function BisectionMethod({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BisectionMethodMeta.title}>
            <BisectionMethodDisplay />
        </ChapterWrapper>
    );
}
