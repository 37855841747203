import React from "react";

import { ChapterWrapper } from "~/ChapterWrapper";
import { Path } from "~/paths";

export const SectionNumericalMathsMeta = {
    title: "Numerical Maths",
    shortTitle: "Numerical Maths",
    path: Path.section_numerical_maths,
    element: (sectionNumber: string) => <SectionNumericalMaths sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

export function SectionNumericalMaths({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionNumericalMathsMeta.title}>
            <p>This chapter is dedicated to various numerical methods.</p>
        </ChapterWrapper>
    );
}
