import React from "react";
import type { FunctionAndInterval, NLMResult } from "./FunctionDisplay";
import { FunctionDisplay } from "./FunctionDisplay";
import { defaultPlotProps } from "./LinearSystemPlot";
import type { PlotPropsExt } from "./FunctionPlot";

function regulaFalsiStep(func: (x: number) => number, interval: [number, number]): NLMResult {
    let [a, b] = interval;
    const [fa, fb] = [func(a), func(b)];

    if (fa * fb >= 0) {
        console.error("Function does not change sign in the interval");
        return {
            x: NaN,
            y: NaN,
            interval: interval,
        };
    }

    // Calculate the next point using linear interpolation (Regula Falsi formula)
    const c = (a * fb - b * fa) / (fb - fa);
    const fc = func(c);

    // Update the interval
    if (fa * fc < 0) {
        b = c; // Root is between a and c
    } else {
        a = c; // Root is between c and b
    }

    return {
        x: c,
        y: fc,
        interval: [a, b],
    };
}

const plotProps: PlotPropsExt = {
    ...defaultPlotProps(),
    displayMode: "regula-falsi",
};

const spec: FunctionAndInterval = {
    func: (x) => Math.exp(x) / 4 - 1, //Math.pow(x, 3),
    interval: [-3, 2],
};

// Graph component to visualize the equations and iterations
export function RegulaFalsiMethodDisplay() {
    return <FunctionDisplay method={regulaFalsiStep} spec={spec} plotProps={plotProps} />;
}
