import React, { useEffect, useRef, useState } from "react";
import { MathJax } from "better-react-mathjax";
import { MathJaxWrapper } from "~/MathJaxWrapper";
import type { TOCProps } from "./TOC";
import { defineTOCElements, TOC } from "./TOC";

export interface ChapterWrapperProps {
    children: React.ReactNode;
    sectionNumber: string;
    title: string;
    TOCSpec?: string[];
}

export const ChapterWrapper: React.FC<ChapterWrapperProps> = ({ children, sectionNumber, title, TOCSpec }) => {
    const chapterRef = useRef<HTMLDivElement>(null);
    const [TOCItems, setTOCItems] = useState<TOCProps>([]);

    useEffect(() => {
        defineTOCElements(chapterRef, TOCSpec !== undefined ? TOCSpec : [], setTOCItems);
    }, []);

    return (
        <MathJaxWrapper>
            <MathJax dynamic={true}>
                <div className="chapter-container">
                    <div className="centered-content">
                        <div className="card" ref={chapterRef}>
                            <h1>
                                <span style={{ paddingRight: 10 }}>{sectionNumber}</span>
                                {title}
                            </h1>
                            {children}
                        </div>
                    </div>
                    {TOCItems.length > 0 ? <TOC headers={TOCItems} /> : null}
                </div>
            </MathJax>
        </MathJaxWrapper>
    );
};
