import React, { useState, useEffect } from "react";

import { MathJax } from "better-react-mathjax";
import { Tooltip } from "react-tooltip";

import type { PlotProps } from "./LinearSystemPlot";
import { initPlot, plotPoints, resetZoomWithoutAccess } from "./LinearSystemPlot";
import { MathJaxWrapper } from "~/MathJaxWrapper";

export interface SystemLinEq {
    A: number[][];
    b: number[];
    x0: number[];
    name?: string;
    comment?: string;
}

// Predefined cases
const predefinedCases: SystemLinEq[] = [
    {
        name: "Convergence",
        A: [
            [2, 1],
            [5, 7],
        ],
        b: [11, 13],
        x0: [-4, 7],
        comment: "Matrix A is strictly diagonally dominant.",
    },
    {
        name: "Divergence",
        A: [
            [5, 7],
            [2, 1],
        ],
        b: [13, 11],
        x0: [5.2, -1.3],
        comment:
            "Matrix A is not strictly diagonally dominant - wrong order of equations, can be fixed by swapping rows.",
    },
    {
        name: "Cycling",
        A: [
            [1, 1],
            [-1, 1],
        ],
        b: [0, 0],
        x0: [2, 6],
        comment: "Matrix A is not strictly diagonally dominant.",
    },
    {
        name: "No solution - coasting",
        A: [
            [1, 1],
            [1, 1],
        ],
        b: [2, -2],
        x0: [-8, 8.5],
        comment: "Matrix A has rank 1, [A|b] has rank 2.",
    },
    {
        name: "Infinitely many solutions - flipping",
        A: [
            [1, 1],
            [1, 1],
        ],
        b: [1, 1],
        x0: [-6, 3],
        comment: "Matrix A has rank 1, [A|b] has rank 1.",
    },
];

export function CaseCard({
    index,
    caseData,
    handleSelectCase,
}: {
    index: number;
    caseData: SystemLinEq;
    handleSelectCase: (systemLinEq: SystemLinEq) => void;
}) {
    return (
        <MathJaxWrapper>
            <MathJax dynamic={true}>
                <div key={index} className="case-entry">
                    <h4 style={{ marginBottom: 0, marginTop: 15 }}>{caseData.name}</h4>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                        {`$$\\begin{align*}
                        A = \\begin{pmatrix}
                            ${caseData.A[0][0]} & ${caseData.A[0][1]} \\\\
                            ${caseData.A[1][0]} & ${caseData.A[1][1]}
                        \\end{pmatrix}, \\quad
                        b = \\begin{pmatrix}
                            ${caseData.b[0]} \\\\
                            ${caseData.b[1]}
                        \\end{pmatrix}, \\quad
                        x^{(0)} = \\begin{pmatrix}
                            ${caseData.x0[0]} \\\\
                            ${caseData.x0[1]}
                        \\end{pmatrix}
                        \\end{align*}$$`}
                    </p>
                    {caseData.comment && (
                        <p style={{ marginBottom: 6, marginTop: 0 }}>
                            <i>{caseData.comment}</i>
                        </p>
                    )}
                    <button onClick={() => handleSelectCase(caseData)}>Select</button>
                </div>
            </MathJax>
        </MathJaxWrapper>
    );
}

// Graph component to visualize the equations and iterations
export function IterativeMethodDisplay({
    method,
    spec,
    plotProps,
}: {
    method: (A: number[][], b: number[], x: number[]) => number[];
    spec: SystemLinEq;
    plotProps: PlotProps;
}) {
    // Input state variables
    const [A, setA] = useState(spec.A); // Matrix A
    const [b, setB] = useState(spec.b); // Right-hand side b
    const [x0, setX0] = useState(spec.x0); // Initial guess x0

    // Iteration state
    const [iterations, setIterations] = useState<number[][]>([x0]);

    // State for the expandable cases section
    const [isExpanded, setIsExpanded] = useState(false);

    // D3 SVG ref
    const svgRef = React.useRef<SVGSVGElement>(null);

    useEffect(() => {
        initPlot(svgRef.current, A, b, x0, plotProps);
        setIterations([x0]);
    }, [A, b, x0]);

    // Update the graph when the iterations change
    useEffect(() => {
        if (!svgRef.current) return;

        plotPoints(iterations, plotProps);
    }, [iterations]);

    // Event handlers for buttons
    const stepForward = () => {
        const newX = method(A, b, iterations[iterations.length - 1]);
        setIterations([...iterations, newX]);
    };

    const stepBackward = () => {
        if (iterations.length > 1) {
            setIterations(iterations.slice(0, iterations.length - 1));
        }
    };

    const resetIterations = () => {
        setIterations([x0]);
    };

    resetIterations;

    // Function to handle selecting a predefined case
    const handleSelectCase = (systemLinEq: SystemLinEq) => {
        plotProps.currentXScale = plotProps.xScale; //reset scale
        plotProps.currentYScale = plotProps.yScale; //reset scale
        resetZoomWithoutAccess(svgRef.current);
        initPlot(svgRef.current, systemLinEq.A, systemLinEq.b, systemLinEq.x0, plotProps);
        setA(systemLinEq.A);
        setB(systemLinEq.b);
        setX0(systemLinEq.x0);
        setIterations([x0]);
        setIsExpanded(false); // Close the expandable section
    };

    return (
        <div>
            {/* Expandable Section */}
            <div style={{ marginBottom: 20 }}>
                <button onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? "Hide Predefined Cases" : "Show Predefined Cases"}
                </button>
                {isExpanded && (
                    <div className="expandable-section">
                        {predefinedCases.map((caseData, index) => (
                            <CaseCard
                                key={index}
                                index={index}
                                caseData={caseData}
                                handleSelectCase={handleSelectCase}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div className="input-container">
                <div>
                    <label>Matrix {"$A$"}:</label>
                    <div className="matrix-input">
                        <input
                            style={{ borderColor: "red" }}
                            type="number"
                            value={A[0][0]}
                            onChange={(e) => setA([[+e.target.value, A[0][1]], A[1]])}
                        />
                        <input
                            style={{ borderColor: "red" }}
                            type="number"
                            value={A[0][1]}
                            onChange={(e) => setA([[A[0][0], +e.target.value], A[1]])}
                        />
                        <input
                            style={{ borderColor: "blue" }}
                            type="number"
                            value={A[1][0]}
                            onChange={(e) =>
                                setA([
                                    [A[0][0], A[0][1]],
                                    [+e.target.value, A[1][1]],
                                ])
                            }
                        />
                        <input
                            style={{ borderColor: "blue" }}
                            type="number"
                            value={A[1][1]}
                            onChange={(e) =>
                                setA([
                                    [A[0][0], A[0][1]],
                                    [A[1][0], +e.target.value],
                                ])
                            }
                        />
                    </div>
                </div>

                <div>
                    <label>Vector {"$b$"}:</label>
                    <div className="vector-input">
                        <input
                            style={{ borderColor: "red" }}
                            type="number"
                            value={b[0]}
                            onChange={(e) => setB([+e.target.value, b[1]])}
                        />
                        <input
                            style={{ borderColor: "blue" }}
                            type="number"
                            value={b[1]}
                            onChange={(e) => setB([b[0], +e.target.value])}
                        />
                    </div>
                </div>

                <div>
                    <label>Starting point:</label>
                    <div className="vector-input">
                        <input type="number" value={x0[0]} onChange={(e) => setX0([+e.target.value, x0[1]])} />
                        <input type="number" value={x0[1]} onChange={(e) => setX0([x0[0], +e.target.value])} />
                    </div>
                </div>
            </div>

            <div>
                <button onClick={resetIterations} data-tooltip-id="tooltip-reset" data-tooltip-content="Reset">
                    &#x21E4;
                </button>
                <button
                    onClick={stepBackward}
                    disabled={iterations.length === 1}
                    data-tooltip-id="tooltip-step-back"
                    data-tooltip-content="Step Back"
                >
                    &larr;
                </button>
                <button
                    onClick={stepForward}
                    data-tooltip-id="tooltip-step-forward"
                    data-tooltip-content="Step Forward"
                >
                    &rarr;
                </button>
                <Tooltip id="tooltip-step-back" />
                <Tooltip id="tooltip-step-forward" />
                <Tooltip id="tooltip-reset" />
            </div>

            <svg ref={svgRef} width={plotProps.width} height={plotProps.height} />
            <table className="simple-table jacobi" style={{ maxWidth: 300 }}>
                <thead>
                    <tr>
                        <th>{"$i$"}</th>
                        <th>{"$x^{(i)}$"}</th>
                        <th>{"$y^{(i)}$"}</th>
                    </tr>
                </thead>
                <tbody>
                    {iterations.map((x, i) => (
                        <tr key={i}>
                            <td>{i}</td>
                            <td>{x[0].toFixed(2)}</td>
                            <td>{x[1].toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
