export enum Path {
    root = "/",

    section_math_foundations = "section-math-foundations",
    section_numerical_maths = "section-numerical-maths",

    functions_notes_mini = "functions-notes-mini",
    gaussseidel_method = "gaussseidel-method",
    goniometric = "goniometric",
    jacobi_method = "jacobi-method",
    matrices_notes = "matrices-notes",

    bisection_method = "bisection-method",
    regula_falsi_method = "regula-falsi-method",
}
