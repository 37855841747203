import React from "react";
import { RegulaFalsiMethodDisplay } from "~/applets";

import { ChapterWrapper } from "~/ChapterWrapper";
import { Path } from "~/paths";

export const RegulaFalsiMethodMeta = {
    title: "Regula Falsi Method",
    shortTitle: "Regula Falsi Method",
    path: Path.regula_falsi_method,
    element: (sectionNumber: string) => <RegulaFalsiMethod sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

export function RegulaFalsiMethod({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={RegulaFalsiMethodMeta.title}>
            <RegulaFalsiMethodDisplay />
        </ChapterWrapper>
    );
}
