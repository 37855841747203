import React from "react";

export type TOCProps = { id: string; text: string }[];

export function TOC({ headers }: { headers: TOCProps }) {
    const handleClick = (id: string) => {
        const element = document.getElementById(id);
        console.log("Scrolling to", id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="toc">
            <p>Contents:</p>
            <ul>
                {headers.map((header) => (
                    <li key={header.id} onClick={() => handleClick(header.id)}>
                        {header.text}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export function defineTOCElements(
    chapterRef: React.RefObject<HTMLDivElement>,
    toc: string[],
    setHeaders: React.Dispatch<React.SetStateAction<{ id: string; text: string }[]>>,
) {
    const headersArray: { id: string; text: string }[] = [];
    const chapterElement = chapterRef.current;
    if (chapterElement) {
        const headersElements = chapterElement.querySelectorAll("h1, h2, h3");
        headersElements.forEach((header) => {
            if (toc.includes(header.id)) {
                headersArray.push({ id: header.id, text: header.textContent || "" });
            }
        });
    }
    setHeaders(headersArray);
}
