import React from "react";
import type { Interval } from "./FunctionDisplay";

interface TableProps {
    intervals: Interval[]; // Assuming each iteration is an array of numbers like [x, y]
}

function isNew(coord: number, interval: Interval): boolean {
    return coord > interval[0] && coord < interval[1];
}

export function IterationIntervalsTable({ intervals }: TableProps) {
    return (
        <table className="simple-table jacobi" style={{ maxWidth: 300 }}>
            <thead>
                <tr>
                    <th>{"$i$"}</th>
                    <th>{"$l^{(i)}$"}</th>
                    <th>{"$r^{(i)}$"}</th>
                </tr>
            </thead>
            <tbody>
                {intervals.map((x, i) => (
                    <tr key={i}>
                        <td>{i}</td>
                        <td style={{ backgroundColor: i > 0 && isNew(x[0], intervals[i - 1]) ? "yellow" : "white" }}>
                            {x[0].toFixed(2)}
                        </td>
                        <td style={{ backgroundColor: i > 0 && isNew(x[1], intervals[i - 1]) ? "yellow" : "white" }}>
                            {x[1].toFixed(2)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
