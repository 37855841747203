import React from "react";

import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";

import "./App.css";
import type { Section } from "./defs";
import { sections } from "./defs";
import { FooterMini } from "./Footer";
import { LandingPage } from "./LandingPage";
import { ContentsMenu } from "./Menu";
import { NavBar } from "./NavBar";
import { Path } from "./paths";

import {
    BisectionMethodMeta,
    FunctionsNotesMiniMeta,
    GaussSeidelMethodMeta,
    GoniometricMeta,
    JacobiMethodMeta,
    MatricesNotesMeta,
    RegulaFalsiMethodMeta,
    SectionMathFoundationsMeta,
    SectionNumericalMathsMeta,
} from "./chapters";
import { version } from "./version";

// vzorec je jen zápisem mentálního obrazu toho, co děláme
// nedosazujeme bezduše do vzorce
//  manipulace vzorce je technická /bez představy/, i když i to lze na
// určitých mezivýsledcích a určitě na finálním výsledku

//
// PAMATUJ SI, ŽE NEPises ucenbinci,
// ale manual k typovym prikladum ktere pokryva cviko
//

const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes("histogram-app");

    return (
        <div className="App" style={{ display: "flex", gap: 0, width: "100vw" }}>
            <div className="sidebar" style={{ display: "flex", flexDirection: "column" }}>
                <NavBar />
                <div className="menu-container" style={{ flexGrow: 1 }}>
                    <ContentsMenu sections={sections} />
                </div>
                <div className="small-text">version {version}</div>
                <FooterMini />
            </div>
            <div className="page" style={{ flexBasis: isHistogramApp ? "0" : "6", flexGrow: 6 }}>
                <Outlet />
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
    };
}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout />,
        children: [
            {
                path: "",
                element: <LandingPage />,
            },
            routerChild(BisectionMethodMeta),
            routerChild(FunctionsNotesMiniMeta),
            routerChild(GaussSeidelMethodMeta),
            routerChild(GoniometricMeta),
            routerChild(JacobiMethodMeta),
            routerChild(MatricesNotesMeta),
            routerChild(RegulaFalsiMethodMeta),
            routerChild(SectionMathFoundationsMeta),
            routerChild(SectionNumericalMathsMeta),
        ],
    },
]);

export default function App() {
    return <RouterProvider router={router} />;
}
