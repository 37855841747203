import React from "react";

import { IterativeMethodDisplay } from "./IterativeMethodDisplay";
import { defaultPlotProps } from "./LinearSystemPlot";

const gaussSeidelStep = (A: number[][], b: number[], x: number[]): number[] => {
    const xNew = [...x]; // Start with the current estimate of x

    for (let i = 0; i < 2; i++) {
        let sum = 0;

        // Use the new values for the previously updated elements
        for (let j = 0; j < 2; j++) {
            if (i !== j) {
                sum += A[i][j] * xNew[j]; // Notice xNew is used for updated values
            }
        }

        // Update the value of x at the current index
        xNew[i] = (b[i] - sum) / A[i][i];
    }

    return xNew; // Return the updated estimate of x
};

const plotProps = defaultPlotProps();

const A = [
    [2, 1],
    [5, 7],
];
const b = [11, 13];
const x0 = [-4, 7];

// Graph component to visualize the equations and iterations
export function GSMethodDisplay() {
    return <IterativeMethodDisplay method={gaussSeidelStep} spec={{ A, b, x0 }} plotProps={plotProps} />;
}
