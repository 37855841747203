import React from "react";

import { ChapterWrapper } from "~/ChapterWrapper";
import { ExternalLink } from "~/ExternalLink";
import Footer from "~/Footer";
import { Path } from "~/paths";

export const MatricesNotesMeta = {
    title: "Matrix-related concepts",
    shortTitle: "Matrices",
    path: Path.matrices_notes,
    element: (sectionNumber: string) => <MatricesNotes sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

const TOCSpec = ["fnm-def", "fnm-simple-fs", "fnm-inverse"];

export function MatricesNotes({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={MatricesNotesMeta.title} TOCSpec={TOCSpec}>
            <p>
                For more details, refer to any book on <strong>linear algebra</strong>.
            </p>

            <h2 id="fnm-def">Matrix concepts</h2>
            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Matrix_(mathematics)">Matrix</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Matrix_multiplication">
                    Matrix multiplication
                </ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Determinant">Determinant</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Identity_matrix">Identity matrix</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Main_diagonal">Main diagonal</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Row_and_column_vectors">
                    Row and column vectors
                </ExternalLink>
                .<br />
            </p>

            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Rank_(linear_algebra)">Rank</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Linear_combination">Linear combination</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Linear_independence">
                    Linear independence
                </ExternalLink>
                .<br />
            </p>

            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Transpose">Transpose</ExternalLink>
                .<br />
            </p>

            <p>For a square matrix {"$A_{n \\times n}$"}, the following are equivalent:</p>

            <ul>
                <li>The matrix is invertible.</li>
                <li>The matrix has full rank ({"$n$"}).</li>
                <li>The columns are linearly independent.</li>
                <li>The row are linearly independent.</li>
                <li>The determinant is nonzero.</li>
                <li>
                    The equation {"$Ax = 0$"} has only the trivial solution {"$x = 0$"}.
                </li>
                <li>
                    For each vector {"$b$"}, the equation {"$Ax = b$"} has a unique solution.
                </li>
            </ul>

            <p>
                In this case, we also say that the matrix is <em>regular</em>.
            </p>
            <p>
                In the opposite case, we say that it is <em>singular</em>.
            </p>

            <Footer />
        </ChapterWrapper>
    );
}
