import React from "react";

import { ChapterWrapper } from "~/ChapterWrapper";
import { Path } from "~/paths";

export const SectionMathFoundationsMeta = {
    title: "Foundations",
    shortTitle: "Foundations",
    path: Path.section_math_foundations,
    element: (sectionNumber: string) => <SectionMathFoundations sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

export function SectionMathFoundations({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionMathFoundationsMeta.title}>
            <p>This chapter provides references for some of the foundational concepts in mathematics.</p>
        </ChapterWrapper>
    );
}
