import React from "react";

const cclogo = require("./assets/by-nc-nd.eu.png");

const Footer: React.FC = () => {
    return (
        <footer>
            {/* Horizontal line */}
            <hr style={{ border: "none", borderTop: "1px solid grey", margin: "20px 0" }} />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                }}
            >
                <i>
                    This work is licensed under&nbsp;
                    <a
                        href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Creative Commons BY-NC-ND 4.0 License
                    </a>
                    .&nbsp;&nbsp;
                </i>
                <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank" rel="noopener noreferrer">
                    <img src={cclogo} alt="Creative Commons Logo" style={{ width: "88px", height: "31px" }} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;

export function FooterMini() {
    return (
        <footer>
            {/* Horizontal line */}
            <hr style={{ border: "none", borderTop: "1px solid grey", margin: "5px 0" }} />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    fontSize: "10px",
                }}
            >
                <p>
                    <i>
                        This work is licensed under&nbsp;
                        <a
                            href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <br /> Creative Commons <br /> BY-NC-ND 4.0 License
                        </a>
                        .&nbsp;&nbsp;
                    </i>
                </p>
                <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank" rel="noopener noreferrer">
                    <img src={cclogo} alt="Creative Commons Logo" style={{ width: "66px", height: "24px" }} />
                </a>
            </div>
        </footer>
    );
}
