import React from "react";

import { MathJaxContext } from "better-react-mathjax";

const config = {
    loader: { load: ["[tex]/html", "[tex]/cancel"] },
    packages: { "[+]": ["cancel"] },
    tex: {
        packages: { "[+]": ["html"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
        ],
        displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
        ],
        processEscapes: false,
    },
};

export function MathJaxWrapper({ children }: { children: JSX.Element }) {
    return (
        <MathJaxContext config={config} version={3}>
            {children}
        </MathJaxContext>
    );
}
