import React from "react";

import { ChapterWrapper } from "~/ChapterWrapper";
import { ExternalLink } from "~/ExternalLink";
import Footer from "~/Footer";
import { Path } from "~/paths";

export const FunctionsNotesMiniMeta = {
    title: "Function-related concepts",
    shortTitle: "Functions",
    path: Path.functions_notes_mini,
    element: (sectionNumber: string) => <FunctionsNotesMini sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

const TOCSpec = ["fnm-def", "fnm-simple-fs", "fnm-inverse"];

// vysvětlit to od kruhu, napsat že bod patří kruhu K ; pak napsat (x,y) \in f

export function FunctionsNotesMini({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionsNotesMiniMeta.title} TOCSpec={TOCSpec}>
            <p>
                For more details, refer to any book on <strong>calculus</strong> or{" "}
                <strong>mathematical analysis</strong>.
            </p>

            <h2 id="fnm-def">Function defined</h2>
            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Cartesian_product">Cartesian product</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Relation_(mathematics)">Relation</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Function_(mathematics)">Function</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Injective_function">Injection</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Surjective_function">Surjection</ExternalLink>
                .<br />
                <ExternalLink href="https://en.wikipedia.org/wiki/Bijection">Bijection</ExternalLink>
                .<br />
            </p>

            <p>
                Any subset of the Cartesian product of two sets is called a <em>relation</em>. A <em>function</em> is a
                specific kind of relation - one that associates each element of the domain with{" "}
                <em>exactly one element</em> of the codomain. An <em>injective</em> function is a function that maps
                distinct elements of the domain to distinct elements of the codomain. A <em>surjective</em> function is
                a function that maps the domain <em>onto</em> the codomain (every element of the codomain is the image
                of some element of the domain). A <em>bijective</em> function is a function that is both injective and
                surjective.
            </p>

            <p>
                A function can be defined by a straightforward formula or a more sophisticated formula (piecewise,
                recursive, etc.) or a verbal description (e.g. as an algoprithm). A physical system or a blackbox can be
                described as a function if it maps inputs to outputs.
            </p>

            <p>
                Two formulas can define the same function: {"$f(x) = \\cos^2(x)$"} and {"$g(x) = 1 - \\sin^2(x)$"} are
                different formulas defining the same function: {"$f = g$"}.
            </p>

            <h2 id="fnm-simple-fs">Real-valued functions of a real variable</h2>

            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Function_of_a_real_variable">
                    Function of a real variable
                </ExternalLink>
                .<br />
            </p>

            <p>
                A line: {"$y = ax + b$"} is a polynomial function of degree 1.&nbsp;
                <ExternalLink href="https://en.wikipedia.org/wiki/Linear_equation">Linear equation</ExternalLink>
                .<br />
            </p>

            <p>
                A quadratic function: {"$y = ax^2 + bx + c$"} is a polynomial function of degree 2.&nbsp;
                <ExternalLink href="https://en.wikipedia.org/wiki/Quadratic_function">Quadratic function</ExternalLink>
                .<br />
            </p>

            <p>
                A polynomial function of degree n: {"$y = a_n x^n + a_{n-1} x^{n-1} + \\ldots + a_1 x + a_0$"}.&nbsp;
                <ExternalLink href="https://en.wikipedia.org/wiki/Polynomial">Polynomial</ExternalLink>
                .<br />
            </p>

            <h2 id="fnm-inverse">Inverse function</h2>
            <p>
                <ExternalLink href="https://en.wikipedia.org/wiki/Inverse_function">Inverse function</ExternalLink>
                .<br />
                The inverse of a function {"$f$"} is denoted {"$f^{-1}$"}; it only exists if {"$f$"} is a bijection. The
                functions {"$f$"} and {"$f^{-1}$"} are then mutually inverse. We have:
                {`$$
                                    f(f^{-1}(x)) = x \\qquad \\text{and} \\qquad f^{-1}(f(x)) = x.
                                $$`}
                The inverse function gives the answer to the question: `&quot;`What input {"$x$"} gives the output{" "}
                {"$y = f(x)$"}?`&quot;` The answer is {"$x = f^{-1}(y)$"}.
            </p>

            <p>
                Common pairs of inverse functions:
                <table className="simple-table">
                    <thead>
                        <tr>
                            <th>Function {"$f$"}</th>
                            <th>Inverse function {"$f^{-1}$"}</th>
                            <th>Domain of {"$f$"}</th>
                            <th>Domain of {"$f^{-1}$"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{"$x$"} (identity)</td>
                            <td>{"$x$"} (identity)</td>
                            <td>{"$\\mathbb{R}$"}</td>
                            <td>{"$\\mathbb{R}$"}</td>
                        </tr>
                        <tr>
                            <td>{"$\\frac{1}{x}$"} (reciprocal)</td>
                            <td>{"$\\frac{1}{x}$"} (reciprocal)</td>
                            <td>{"$\\mathbb{R} \\setminus \\{0\\}$"}</td>
                            <td>{"$\\mathbb{R} \\setminus \\{0\\}$"}</td>
                        </tr>
                        <tr>
                            <td>{"$x^2$"} (squared)</td>
                            <td>{"$\\sqrt{x}$"} (square root)</td>
                            <td>{"$[0, \\infty)$"}</td>
                            <td>{"$[0, \\infty)$"}</td>
                        </tr>
                        <tr>
                            <td>{"$e^x$"}</td>
                            <td>{"$\\ln x $"}</td>
                            <td>{"$\\mathbb{R}$"}</td>
                            <td>{"$(0, \\infty)$"}</td>
                        </tr>
                        <tr>
                            <td>{"$10^x$"}</td>
                            <td>{"$\\log x $"}</td>
                            <td>{"$\\mathbb{R}$"}</td>
                            <td>{"$(0, \\infty)$"}</td>
                        </tr>
                        <tr>
                            <td>{"$\\sin x $"}</td>
                            <td>{"$\\arcsin x $"}</td>
                            <td>{"$[-\\frac{\\pi}{2}, \\frac{\\pi}{2}]$"}</td>
                            <td>{"$[-1, 1]$"}</td>
                        </tr>
                        <tr>
                            <td>{"$\\cos x$"}</td>
                            <td>{"$\\arccos x$"}</td>
                            <td>{"$[0, \\pi]$"}</td>
                            <td>{"$[-1, 1]$"}</td>
                        </tr>
                        <tr>
                            <td>{"$\\tan x$"}</td>
                            <td>{"$\\arctan x$"}</td>
                            <td>{"$\\mathbb{R}$"}</td>
                            <td>{"$(-\\frac{\\pi}{2}, \\frac{\\pi}{2})$"}</td>
                        </tr>
                    </tbody>
                </table>
            </p>

            <Footer />
        </ChapterWrapper>
    );
}
