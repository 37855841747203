import React from "react";
import Footer from "./Footer";

export function LandingPage() {
    return (
        <div className="chapter-container">
            <div className="centered-content">
                <h1>Mathematics 3 (BPA-MA3)</h1>
                <em>Notes for Tutorials</em>
                <br />
                <br />
                <em>Ing. Jan Novotný</em>

                <p>In development...</p>

                <Footer />
            </div>
        </div>
    );
}
