import React from "react";

import { Home } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export function NavBar() {
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate("");
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: 10 }}>
            <div>
                <button
                    className="navigation-icon"
                    data-tooltip-id="tooltip-home"
                    data-tooltip-content="Home"
                    onClick={navigateHome}
                >
                    <Home size={20} />
                </button>
                <Tooltip id="tooltip-home" />
            </div>
        </div>
    );
}
