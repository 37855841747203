import React from "react";
import type { FunctionAndInterval, NLMResult } from "./FunctionDisplay";
import { FunctionDisplay } from "./FunctionDisplay";
import { defaultPlotProps } from "./LinearSystemPlot";
import type { PlotPropsExt } from "./FunctionPlot";

// precalculate all points
const bisect = (f: (x: number) => number, a: number, b: number, tol: number, maxIter: number) => {
    const iterData = [];
    let fa = f(a);
    let fb = f(b);
    if (fa * fb >= 0) {
        console.error("Function does not change sign in the interval");
        return [];
    }

    let c = 0;
    for (let i = 0; i < maxIter; i++) {
        c = (a + b) / 2;
        const fc = f(c);
        iterData.push([a, b, c, fc]);

        if (Math.abs(fc) < tol || Math.abs(b - a) < tol) break;

        if (fa * fc < 0) {
            b = c;
            fb = fc;
        } else {
            a = c;
            fa = fc;
        }
    }
    return iterData;
};

function bisectStep(func: (x: number) => number, interval: [number, number]): NLMResult {
    let [a, b] = interval;
    const [fa, fb] = [func(a), func(b)];
    if (fa * fb >= 0) {
        console.error("Function does not change sign in the interval");
        return {
            x: NaN,
            y: NaN,
            interval: interval,
        };
    }

    const c = (a + b) / 2;
    const fc = func(c);
    if (fa * fc < 0) {
        b = c;
        //fb = fc;
    } else {
        a = c;
        //fa = fc;
    }
    return {
        x: c,
        y: fc,
        interval: [a, b],
    };
}

const plotProps: PlotPropsExt = {
    ...defaultPlotProps(),
    displayMode: "bisection",
};

const spec: FunctionAndInterval = {
    func: (x) => Math.exp(x) / 4 - 1, //Math.pow(x, 3),
    interval: [-2, 2],
};

// Graph component to visualize the equations and iterations
export function BisectionMethodDisplay() {
    return <FunctionDisplay method={bisectStep} spec={spec} plotProps={plotProps} />;
}
