import React from "react";

import { GSMethodDisplay } from "~/applets";
import { ChapterWrapper } from "~/ChapterWrapper";
import { Path } from "~/paths";

export const GaussSeidelMethodMeta = {
    title: "Gauss-Seidel Method",
    shortTitle: "Gauss-Seidel Method",
    path: Path.gaussseidel_method,
    element: (sectionNumber: string) => <GaussSeidelMethod sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

export function GaussSeidelMethod({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={GaussSeidelMethodMeta.title}>
            <GSMethodDisplay />
        </ChapterWrapper>
    );
}
