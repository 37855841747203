import React from "react";

import { ChapterWrapper } from "~/ChapterWrapper";
import { ExternalLink } from "~/ExternalLink";
import Footer from "~/Footer";
import { Path } from "~/paths";

const diagram_sincos = require("../assets/images/diagram_sincos.png");

export const GoniometricMeta = {
    title: "Trigonometry",
    shortTitle: "Trigonometry",
    path: Path.goniometric,
    element: (sectionNumber: string) => <Goniometric sectionNumber={sectionNumber} />,
    sectionNumber: "",
};

// /ˌtrɪɡ.əˈnɒm.ə.tri/

const TOCSpec = [
    "trig-pyth",
    "trig-shifts",
    "trig-doublearg",
    "trig-angle-sum",
    "trig-sum-to-product",
    "trig-product-to-sum",
    "trig-other",
    "trig-deriv",
];

export function Goniometric({ sectionNumber }: { sectionNumber: string }) {
    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={GoniometricMeta.title} TOCSpec={TOCSpec}>
            <ExternalLink href="https://en.wikipedia.org/wiki/List_of_trigonometric_identities">
                List of trigonometric identities
            </ExternalLink>
            .
            {/*
             */}
            <h2 id="trig-pyth">Pythagorean identity</h2>
            {`$$\\begin{align*}
                \\cos^2 x + \\sin^2 x & = 1
            \\end{align*}$$`}
            Represents the Pythagorean theorem in a right triangle whose hypotenuse has length 1. The lenghts of the two
            legs are {"$\\sin x$"} a {"$\\cos x$"}.{/* /pɪˌθæɡ.əˈriː.ən ˈθiː.rəm/ */}
            {/* /haɪˈpɒt.ən.juːz/ */}
            {/*
             */}
            <h2 id="trig-shifts">Moving the graphs along the x axis</h2>
            {`$$\\begin{align*}
                \\sin\\left(-x\\right) & = - \\sin x & \\cos\\left(-x\\right) & = \\cos x \\\\
                \\sin\\left(x \\pm \\pi\\right) & = - \\sin x  & \\cos\\left(x \\pm \\pi\\right) & = - \\cos x \\\\
                \\sin\\left(x + \\frac{\\pi}{2}\\right) & = \\cos x & \\cos\\left(x + \\frac{\\pi}{2}\\right) & = - \\sin x \\\\
                \\sin\\left(x - \\frac{\\pi}{2}\\right) & = - \\cos x & \\cos\\left(x - \\frac{\\pi}{2}\\right) & = \\sin x
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-doublearg">Double-angle formulae</h2>
            {`$$\\begin{align*}
                \\sin 2x  &= 2 \\sin x \\cos x & \\cos 2x &= \\cos^2 x - \\sin^2 x
            \\end{align*}$$`}
            {`$$\\begin{align*}
                \\sin^2 x  &= \\frac{1 - \\cos 2x}{2} & \\cos^2 x  &= \\frac{1 + \\cos 2x}{2}
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-angle-sum">Angle sum identities</h2>
            {`$$\\begin{align*}
                \\sin(x+y) &= \\sin x \\cos y + \\cos x \\sin y \\\\
                \\sin(x-y) &= \\sin x \\cos y - \\cos x \\sin y \\\\
                \\cos(x+y) &= \\cos x \\cos y - \\sin x \\sin y \\\\
                \\cos(x-y) &= \\cos x \\cos y + \\sin x \\sin y
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-sum-to-product">Sum-to-product identities</h2>
            {`$$\\begin{align*}
                \\sin a +  \\sin b &= 2 \\sin \\frac{a+b}{2} \\cos \\frac{a-b}{2} \\\\
                \\sin a -  \\sin b  &= 2 \\cos \\frac{a+b}{2} \\sin \\frac{a-b}{2} \\\\
                \\cos a +  \\cos b &= 2 \\cos \\frac{a+b}{2} \\cos \\frac{a-b}{2} \\\\
                \\cos a -  \\cos b &= - 2 \\sin \\frac{a+b}{2} \\sin \\frac{a-b}{2}
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-product-to-sum">Product-to-sum identities</h2>
            {`$$\\begin{align*}
                \\sin x \\cos y &= \\frac{1}{2}\\left[\\sin(x+y) + \\sin(x-y)\\right] \\\\
                \\cos x \\sin y &= \\frac{1}{2}\\left[\\sin(x+y) - \\sin(x-y)\\right] \\\\
                \\cos x \\cos y &= \\frac{1}{2}\\left[\\cos(x+y) + \\cos(x-y)\\right] \\\\
                \\sin x \\sin y &= \\frac{1}{2}\\left[\\cos(x+y) - \\cos(x-y)\\right] \\\\
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-other">Other</h2>
            {`$$\\begin{align*}
                \\vert \\sin x \\vert &= \\sqrt{1 - \\cos^2 x} & \\vert \\cos x \\vert &= \\sqrt{1 - \\sin^2 x}
            \\end{align*}$$`}
            {`$$\\begin{align*}
                \\vert \\sin x \\vert &= \\sqrt{\\frac{1 - \\cos 2x}{2}} & \\vert \\cos x \\vert &= \\sqrt{\\frac{1 + \\cos 2x}{2}} \\\\
                \\Big\\vert \\sin\\frac{x}{2} \\Big\\vert &= \\sqrt{\\frac{1 - \\cos x}{2}} & \\Big\\vert \\cos\\frac{x}{2} \\Big\\vert &= \\sqrt{\\frac{1 + \\cos x}{2}}
            \\end{align*}$$`}
            {/*
             */}
            <h2 id="trig-deriv">Notes on deriving the formulas</h2>
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
                <img src={diagram_sincos} style={{ width: "55%" }} />
                <p>
                    Author of diagrams:&nbsp;
                    <ExternalLink href="https://math.stackexchange.com/users/409/blue">
                        https://math.stackexchange.com/users/409/blue
                    </ExternalLink>
                    .
                </p>
            </div>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad & \\sin(x+y) = \\sin x \\cos y + \\cos x \\sin y, \\ \\  \\text{and let } y = x \\\\
                    \\text{Consequence:} \\quad & \\sin(x+x) = \\sin x \\cos x + \\cos x \\sin x  \\quad \\rightarrow \\quad {\\color{red}\\sin 2x  = 2 \\sin x \\cos x}
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad & \\cos(x+y) = \\cos x \\cos y - \\sin x \\sin y, \\ \\  \\text{and let } y = x \\\\
                    \\text{Consequence:} \\quad &  \\cos(x+x) = \\cos x \\cos x - \\sin x \\sin x  \\quad \\rightarrow \\quad {\\color{red}\\cos 2x = \\cos^2 x - \\sin^2 x}
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad && \\cos 2x &= \\cos^2 x - \\sin^2 x \\\\
                    \\text{and from:}     \\quad         &&           1 &= \\cos^2 x + \\sin^2 x \\ \\ \\ \\ \\text{and add the equations (the left- and right-hand sides)}  \\\\
                    \\text{Consequence:} \\quad  &&  1 + \\cos 2x &= (\\cos^2 x + \\sin^2 x) + (\\cos^2 x - \\sin^2 x)  \\\\
                    \\rightarrow             \\quad   &&  1 + \\cos 2x &= 2 \\cos^2 x  \\quad \\rightarrow \\quad {\\color{red}\\cos^2 x  = \\frac{1 + \\cos 2x}{2}}
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad  && \\cos 2x &= \\cos^2 x - \\sin^2 x \\\\
                    \\text{a z:}        \\quad  &&        1 &= \\cos^2 x + \\sin^2 x \\ \\ \\ \\ \\text{and subtract the equations (lhs', rhs')}  \\\\
                    \\text{Consequence:} \\quad &&  1 - \\cos 2x &= (\\cos^2 x + \\sin^2 x) - (\\cos^2 x - \\sin^2 x)  \\\\
                    \\rightarrow         \\quad &&  1 - \\cos 2x &= 2 \\sin^2 x  \\quad \\rightarrow \\quad {\\color{red}\\sin^2 x  = \\frac{1 - \\cos 2x}{2}}
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad    && \\sin(x+y) &= \\sin x \\cos y + \\cos x \\sin y, \\qquad \\text{and substitute } (-y) \\text{ for } y \\\\
                    \\text{Consequence:} \\quad   && \\sin\\left(x+ (-y)\\right) &= \\sin x \\cos(-y) + \\cos x \\sin(-y)  \\\\
                    \\text{ + use:} \\quad        && \\Big\\vert \\cos(-y) &= \\cos y, \\ \\sin(-y) = - \\sin y \\Big\\vert  \\\\
                    \\rightarrow \\quad           &&  {\\color{red}\\sin\\left(x - y\\right) \\ } & {\\color{red} = \\sin x \\cos y - \\cos x \\sin y }
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad    && \\sin(x+y) &= \\sin x \\cos y + \\cos x \\sin y, \\qquad \\text{and substitute } (y + \\frac{\\pi}{2}) \\text{ for } y \\\\
                    \\text{Consequence:} \\quad   && \\sin\\left(x+ (y + \\frac{\\pi}{2})\\right) &= \\sin x \\cos(y + \\frac{\\pi}{2}) + \\cos x \\sin(y + \\frac{\\pi}{2})  \\\\
                    \\text{ + využijeme:} \\quad  && \\Big\\vert \\cos(y + \\frac{\\pi}{2}) &= -\\sin y, \\ \\sin(y + \\frac{\\pi}{2}) = \\cos y \\Big\\vert  \\\\
                    \\rightarrow \\quad           && \\sin\\left((x+ y) + \\frac{\\pi}{2}\\right) &= \\sin x (-\\sin y) + \\cos x \\cos y  \\\\
                    \\rightarrow \\quad           &&  {\\color{red}\\cos\\left(x + y\\right) \\ } & {\\color{red} = \\cos x \\cos y - \\sin x \\sin y }
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:} \\quad    && \\cos(x+y) &= \\cos x \\cos y - \\sin x \\sin y, \\qquad \\text{a dosaďme } (-y) \\text{ za } y \\\\
                    \\text{Consequence:} \\quad   && \\cos\\left(x+ (-y)\\right) &= \\cos x \\cos(-y) - \\sin x \\sin(-y)  \\\\
                    \\text{ + využijeme:} \\quad  && \\Big\\vert \\cos(-y) &= \\cos y, \\ \\sin(-y) = - \\sin y \\Big\\vert  \\\\
                    \\rightarrow \\quad           &&  {\\color{red}\\cos\\left(x - y\\right) \\ } & {\\color{red} = \\cos x \\cos y + \\sin x \\sin y }
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:}   \\quad   && \\sin(x+y) &= \\sin x \\cos y + \\cos x \\sin y \\\\
                    \\text{and from:}     \\quad   && \\sin(x-y) &= \\sin x \\cos y - \\cos x \\sin y \\\\
                    \\text{Substitute:}   \\quad   &&  a &= x + y,  \\ b = x - y, \\\\
                                        \\quad   &&  x &= \\frac{a+b}{2},  \\ y = \\frac{a-b}{2}, \\\\
                    \\rightarrow          \\quad   && \\sin(x+y) + \\sin(x-y) & = \\left(\\sin x \\cos y + \\cos x \\sin y\\right) + \\left(\\sin x \\cos y - \\cos x \\sin y\\right) \\\\
                    \\rightarrow          \\quad   && \\sin(x+y) + \\sin(x-y) & = 2 \\sin x \\cos y \\\\
                    \\rightarrow          \\quad   &&  {\\color{red}\\sin a +  \\sin b} \\ &  {\\color{red} = 2 \\sin \\frac{a+b}{2} \\cos \\frac{a-b}{2}} \\\\
                    \\\\
                    \\rightarrow          \\quad   && \\sin(x+y) - \\sin(x-y) & = \\left(\\sin x \\cos y + \\cos x \\sin y\\right) - \\left(\\sin x \\cos y - \\cos x \\sin y\\right) \\\\
                    \\rightarrow          \\quad   && \\sin(x+y) - \\sin(x-y) & = 2 \\cos x \\sin y \\\\
                    \\rightarrow          \\quad   &&  {\\color{red}\\sin a -  \\sin b} \\ &  {\\color{red} = 2 \\cos \\frac{a+b}{2} \\sin \\frac{a-b}{2}}
                \\end{align*}$$`}
            </p>
            <p className="trig">
                {`$$\\begin{align*}
                    \\text{Start from:}   \\quad   && \\cos(x+y) &= \\cos x \\cos y - \\sin x \\sin y \\\\
                    \\text{and from:}     \\quad   && \\cos(x-y) &= \\cos x \\cos y + \\sin x \\sin y \\\\
                    \\text{Substitute:}   \\quad   &&  a &= x + y,  \\ b = x - y, \\\\
                                        \\quad   &&  x &= \\frac{a+b}{2},  \\ y = \\frac{a-b}{2}, \\\\
                    \\rightarrow          \\quad   && \\cos(x+y) + \\cos(x-y) & = \\left(\\cos x \\cos y - \\sin x \\sin y\\right) + \\left(\\cos x \\cos y + \\sin x \\sin y\\right) \\\\
                    \\rightarrow          \\quad   && \\cos(x+y) + \\cos(x-y) & = 2 \\cos x \\cos y \\\\
                    \\rightarrow          \\quad   &&  {\\color{red}\\cos a +  \\cos b} \\ &  {\\color{red} = 2 \\cos \\frac{a+b}{2} \\cos \\frac{a-b}{2}} \\\\
                    \\\\
                    \\rightarrow          \\quad   && \\cos(x+y) - \\cos(x-y) & = \\left(\\cos x \\cos y - \\sin x \\sin y\\right) - \\left(\\cos x \\cos y + \\sin x \\sin y\\right) \\\\
                    \\rightarrow          \\quad   && \\cos(x+y) - \\cos(x-y) & = - 2 \\cos x \\sin y \\\\
                    \\rightarrow          \\quad   && {\\color{red}\\cos a -  \\cos b} \\ &  {\\color{red} = - 2 \\sin \\frac{a+b}{2} \\sin \\frac{a-b}{2}}
                \\end{align*}$$`}
            </p>
            <Footer />
        </ChapterWrapper>
    );
}
